import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';
import { ThemeProvider, ThemeContext } from '../context/ThemeProvider';

import SEO from '../components/seo';
import headerLogo from '../images/logo_footer.svg';
import '../styles/checkout.scss';

export class InquirySuccess extends Component {
    componentDidMount() {
        // Return to home page if location props is empty
        const { location } = this.props;

        if (!location.state) {
            navigate('/', { replace: true });
        }
    }

    render() {
        const { theme, seo } = this.props.pageContext;
        const { location } = this.props;

        let guestName = '', propertyAddress = '', guestCount = 0, checkinDate = '', checkoutDate = '';

        if (location.state) {
            guestName = location.state.guestName || '';
            propertyAddress = location.state.propertyAddress || '';
            guestCount = location.state.guestCount || '';
            checkinDate = location.state.checkinDate || '';
            checkoutDate = location.state.checkoutDate || '';
        }

        return (
            <React.Fragment>
                <SEO {...seo} />
                <ThemeProvider theme={theme}>
                    <ThemeContext.Consumer>
                        {
                            value => 
                            <div className="checkout-success">
                                <div className="checkout__header-logo">
                                    <img src={headerLogo} alt="logo" />
                                </div>
                                <div className="checkout-success__inner-container">
                                    <h1 className="checkout-success--title">Inquiry Confirmation</h1>
                                    <p className="checkout-success--greetings">{`Hello ${guestName}, We are delighted you choose to stay with us at Dinesen Collection.`}</p>
                                    <div className="checkout-success--schedule">
                                        <div className="checkout-success--schedule__checkin">
                                            <span className="checkin--label">Check-in</span>
                                            <span className="checkin--date">{checkinDate}</span>
                                        </div>
                                        <div className="checkout-success--schedule__checkout">
                                            <span className="checkout--label">Check-out</span>
                                            <span className="checkout--date">{checkoutDate}</span>
                                        </div>
                                    </div>
                                    <div className="checkout-success--info">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Property Full Address</td>
                                                    <td>{propertyAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td>Number of Guests</td>
                                                    <td>{guestCount}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="checkout-success__action">
                                        <Link to='/' className={`btn ${value.buttonPrimary}`}>
                                            Go back to Home Page
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </ThemeContext.Consumer>
                </ThemeProvider>
            </React.Fragment>
        )
    }
}

export default InquirySuccess;